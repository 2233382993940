import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import style from "./DailyEcologie.module.scss";

const DailyEcologie = ({ slice }) => (
  <section>
    <div className={style.ecologie}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-blc-title-s1 multiple center">
            <div className="splayer">
              <PrismicRichText field={slice.primary.title} />
            </div>
          </div>
          <div className="lk-blc-stitle">
            <PrismicRichText field={slice.primary.sousTitre} />
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-between lk-wrap">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div>
                  <div className={style.feat}>
                    <Image
                      src={item.feature.url}
                      alt={item.feature.alt}
                      title={item.feature.alt}
                      width="117"
                      height="136"
                    />
                  </div>
                </div>
                <div>
                  <div className={style.label}>
                    <PrismicRichText field={item.titre} />
                  </div>
                  <div className={style.text}>
                    <PrismicRichText field={item.text} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DailyEcologie;
