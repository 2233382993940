import React from "react";
import { PrismicRichText } from "@prismicio/react";
import TrustPilot from "@components/pages/bundles/parts/TrustPilot/TrustPilot";
import style from "./HomeTrustPilotReviews.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.HomeTrustpilotReviewsSlice} HomeTrustpilotReviewsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HomeTrustpilotReviewsSlice>} HomeTrustpilotReviewsProps
 * @param { HomeTrustpilotReviewsProps }
 */
const HomeTrustpilotReviews = ({ slice }) => {
  const title = <PrismicRichText field={slice.primary.title} />;
  const subtitle = <PrismicRichText field={slice.primary.subtitle} />;

  return (
    <section className={style.wrapper}>
      <TrustPilot title={title} subtitle={subtitle} />
    </section>
  );
};

export default HomeTrustpilotReviews;
