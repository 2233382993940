import React from "react";
import cn from "clsx";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { Text } from "@components/ui";
import { ImageField, KeyTextField, RichTextField } from "@prismicio/types";
import style from "./DailyHealthyEatingChoices.module.scss";

interface Props {
  icon: ImageField;
  label: KeyTextField;
  caption?: RichTextField;
  className?: string;
}
function ChoiceItem({ icon, label, caption, className }: Props) {
  return (
    <div className={cn(className, style.item)}>
      <div>
        <div className={style.icon}>
          <PrismicImage field={icon} />
        </div>
        <Text className={style.label}>{label}</Text>
        {caption && (
          <Text className={style.caption}>
            <PrismicRichText field={caption} />
          </Text>
        )}
      </div>
    </div>
  );
}

export default ChoiceItem;
