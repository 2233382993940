import { withTranslation, WithTranslation } from "next-i18next";
import React, { Component, ReactNode } from "react";
import { Container } from "@components/ui";
import { IMessage, TFunctionTrans } from "@middleware/types";

interface Props extends WithTranslation {
  children?: ReactNode;
  translation: TFunctionTrans;
  setErreur: (error: IMessage) => void;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundaryComponent extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    this.props.setErreur({
      text: error.message,
      type: "ERROR",
    });
  }

  public render() {
    const { translation } = this.props;

    if (this.state.hasError) {
      return (
        <Container className="p-3 text-center alert-text">
          <div className="lk-wrap">
            <div className="lk-wrap-content">
              <h1>{translation("error.sorryMessage")}</h1>
              <p>{translation("error.sorryMessageText")}</p>
            </div>
          </div>
        </Container>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent);
