import { Text } from "@components/ui";
import style from "./BlockParrainage.module.scss";

interface Props {
  imageUrl: string;
  imageAlt: string;
  amount: number;
  label: string;
  description: string;
}

export const Loyalty = ({
  imageUrl,
  imageAlt,
  amount,
  label,
  description,
}: Props) => {
  return (
    <div className={style.col}>
      <Text variant="span">{amount}</Text>
      <Text variant="span" className={style.label}>
        {label}
      </Text>
      <Text variant="p">{description}</Text>
    </div>
  );
};
