export const sliderSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 3.1,
  slidesToScroll: 1,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3.1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1.5,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.1,
      },
    },
  ],
};
