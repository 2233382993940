import React from "react";
import Image from "next/image";
import style from "./GroupHeader.module.scss";

const GroupHeader = ({ slice }) => {
  return (
    <section className={style.root}>
      {slice.primary.header ? (
        <Image
          src={slice.primary.header.url}
          alt={slice.primary.header.alt}
          width={slice.primary.width}
          height={slice.primary.height}
        />
      ) : null}
      <style jsx>{`
        section {
          background-color: ${slice.primary.background};
        }
      `}</style>
    </section>
  );
};

export default GroupHeader;
