import { EGroupCustomer } from "@middleware/types";
import { Content } from "@prismicio/client";

export const getCorrectGroupSliceForHeader = (
  prismicGroupPage: Content.HeaderGroupeDocument,
  customerGroup: string
) => {
  if (customerGroup === EGroupCustomer.NONE) {
    return [];
  }

  return prismicGroupPage.data.slices.filter(
    (slice) => slice.primary.group_code === customerGroup
  );
};

export const getCorrectGroupSliceForBundles = (
  prismicGroupPage: Content.TexteFormuleGroupeDocument,
  customerGroup: string
) => {
  if (customerGroup === EGroupCustomer.NONE) {
    return [];
  }

  return prismicGroupPage.data.slices.filter(
    (slice) => slice.primary.group_code === customerGroup
  );
};
