import { PrismicRichText } from "@prismicio/react";
import style from "./Footer.module.scss";

const Footer = ({ slice }) => (
  <section className="section-footer">
    <div className={style.footer}>
      <div className="lk-wrap">
        <div className="lk-flex space-between lk-wrap">
          {slice?.items?.map((item, i) => (
            <div className={style.item} key={i}>
              <div className={style.label}>
                <PrismicRichText field={item.titre} />
              </div>
              <div className={style.text}>
                <PrismicRichText field={item.text} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
