import { TAccordionItem } from "@middleware/types";
import { FC, useState } from "react";
import cn from "clsx";
import { useTranslation } from "next-i18next";
import style from "./Accordion.module.scss";

interface AccordionProps {
  items: TAccordionItem[];
  className?: string;
}

const Accordion: FC<AccordionProps> = ({ items, className }) => {
  const { t } = useTranslation();
  const isEmpty = items.length === 0;
  const [activeItem, setActiveItem] = useState<number | false>(false);
  const handleCollapseItem = (index: number) => {
    const value = index === activeItem ? false : index;
    setActiveItem(value);
  };

  return (
    <div className={cn(style.accordion, className)}>
      <div className="lk-wrap">
        <div className="padding">
          {items.map((AccordionItem, index) => (
            <div className={style.accordionItemWrap} key={index}>
              <header>
                <div className="headerWrap">
                  <button
                    className={cn({ [style.collapsed]: index !== activeItem })}
                    onClick={() => handleCollapseItem(index)}
                  >
                    {AccordionItem.headerTitle}
                  </button>
                </div>
              </header>
              <section>
                {activeItem === index && (
                  <div>
                    <div>{AccordionItem.bodyContent}</div>
                  </div>
                )}
              </section>
            </div>
          ))}
          {isEmpty && (
            <div className={style.noDataFounded}>
              {t("pages.faq.noDataFounded")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
