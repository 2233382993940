// Code generated by Slice Machine. DO NOT EDIT.

import Accordion from './Accordion';
import AvisClients from './AvisClients';
import BarPromotion from './BarPromotion';
import BilanQuestion from './BilanQuestion';
import BilanResults from './BilanResults';
import BlocAssiette from './BlocAssiette';
import BlocManger from './BlocManger';
import BlocParrainage from './BlocParrainage';
import BlockMealsCms from './BlockMealsCms';
import BlockParrainage from './BlockParrainage';
import BlockPlatsFrais from './BlockPlatsFrais';
import BlogInfluenceurs from './BlogInfluenceurs';
import ContentSimple from './ContentSimple';
import CookingWithMauro from './CookingWithMauro';
import DailyEcologie from './DailyEcologie';
import DailyHealthyEatingChoices from './DailyHealthyEatingChoices';
import DailyHow from './DailyHow';
import DiscoverCustomerAccount from './DiscoverCustomerAccount';
import EcoFriendlyPractices from './EcoFriendlyPractices';
import Faq from './Faq';
import FaqHead from './FaqHead';
import FeaturedArticles from './FeaturedArticles';
import Footer from './Footer';
import GroupBundleInfo from './GroupBundleInfo';
import GroupHeader from './GroupHeader';
import HomeHead from './HomeHead';
import HomeHeadWithVideo from './HomeHeadWithVideo';
import HomeTrustpilotReviews from './HomeTrustpilotReviews';
import InKitchend from './InKitchend';
import MangezMieux from './MangezMieux';
import MealProcessSteps from './MealProcessSteps';
import OffrePreferentielle from './OffrePreferentielle';
import Partners from './Partners';
import PreparedDishesCarousel from './PreparedDishesCarousel';
import SliderRepas from './SliderRepas';
import SuccessReferrals from './SuccessReferrals';
import TitrePage from './TitrePage';
import VideoMauro from './VideoMauro';

export {
	Accordion,
	AvisClients,
	BarPromotion,
	BilanQuestion,
	BilanResults,
	BlocAssiette,
	BlocManger,
	BlocParrainage,
	BlockMealsCms,
	BlockParrainage,
	BlockPlatsFrais,
	BlogInfluenceurs,
	ContentSimple,
	CookingWithMauro,
	DailyEcologie,
	DailyHealthyEatingChoices,
	DailyHow,
	DiscoverCustomerAccount,
	EcoFriendlyPractices,
	Faq,
	FaqHead,
	FeaturedArticles,
	Footer,
	GroupBundleInfo,
	GroupHeader,
	HomeHead,
	HomeHeadWithVideo,
	HomeTrustpilotReviews,
	InKitchend,
	MangezMieux,
	MealProcessSteps,
	OffrePreferentielle,
	Partners,
	PreparedDishesCarousel,
	SliderRepas,
	SuccessReferrals,
	TitrePage,
	VideoMauro,
};

export const components = {
	accordion: Accordion,
	avis_clients: AvisClients,
	bar_promotion: BarPromotion,
	bilan_question: BilanQuestion,
	bilan_results: BilanResults,
	bloc_assiette: BlocAssiette,
	bloc_manger: BlocManger,
	bloc_parrainage: BlocParrainage,
	block_meals_cms: BlockMealsCms,
	block_parrainage: BlockParrainage,
	block_plats_frais: BlockPlatsFrais,
	blog_influenceurs: BlogInfluenceurs,
	content_simple: ContentSimple,
	cooking_with_mauro: CookingWithMauro,
	daily_ecologie: DailyEcologie,
	daily_healthy_eating_choices: DailyHealthyEatingChoices,
	daily_how: DailyHow,
	decouvre_espace_client: DiscoverCustomerAccount,
	eco_friendly_practices: EcoFriendlyPractices,
	faq: Faq,
	faq_head: FaqHead,
	featured_articles: FeaturedArticles,
	footer: Footer,
	group_bundle_info: GroupBundleInfo,
	group_header: GroupHeader,
	home_head: HomeHead,
	home_head_with_video: HomeHeadWithVideo,
	home_trustpilot_reviews: HomeTrustpilotReviews,
	in_kitchend: InKitchend,
	mangez_mieux: MangezMieux,
	meal_process_steps: MealProcessSteps,
	offre_preferentielle: OffrePreferentielle,
	partners: Partners,
	prepared_dishes_carousel: PreparedDishesCarousel,
	slider_repas: SliderRepas,
	success_referrals: SuccessReferrals,
	titre_page: TitrePage,
	video_mauro: VideoMauro,
};
