import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import Image from "next/image";
import { Text } from "@components/ui";
import style from "./MangezMieux.module.scss";

const MangezMieux = ({ slice }) => (
  <section>
    <div className={style.mangez}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-blc-title-s1 center">
            <div className="splayer">
              <PrismicRichText field={slice.primary.title} />
            </div>
          </div>
        </div>
      </div>
      <div className={style.wrap}>
        <div className="lk-wrap-content">
          <div className={cn("content", style.content)}>
            <div className={style.feature}>
              <Image
                className={style.image}
                src={slice.primary.feature.url}
                sizes="(min-width: 630px) 300px, (min-width: 768px) 410px"
                fill
                style={{
                  objectFit: "none",
                }}
              />
            </div>

            <div className="lk-flex space-between lk-flex-mob-colomn">
              <div className={cn(style.col, style.orange)}>
                <Text variant="span" className={style.colTitle}>
                  {slice.primary.titre_orange}
                </Text>
                <div className={style.row}>
                  <Text variant="span">{slice.primary.label_orange_1}</Text>
                  <Text variant="p">{slice.primary.text_orange_1}</Text>
                </div>
                <div className={cn(style.row, style.aling)}>
                  <Text variant="span">{slice.primary.label_orange_2}</Text>
                  <Text variant="p">{slice.primary.text_orange_2}</Text>
                </div>
                <div className={cn(style.row, style.aling)}>
                  <Text variant="span">{slice.primary.label_orange_3}</Text>
                  <Text variant="p">{slice.primary.text_orange_3}</Text>
                </div>
                <div className={cn(style.row, style.last)}>
                  <Text variant="span">{slice.primary.label_orange_4}</Text>
                  <Text variant="p">{slice.primary.text_orange_4}</Text>
                </div>
                <div className={style.indice}>
                  <Text variant="p" html={slice.primary.recap_orange[0].text} />
                </div>
              </div>
              <div className={cn("only_mobile", style.img)}>
                <Image
                  src={slice.primary.feature.url}
                  alt={slice.primary.feature.alt}
                  title={slice.primary.feature.alt}
                  width="711"
                  height="882"
                />
              </div>
              <div className={cn(style.col, style.vert)}>
                <Text variant="span" className={style.colTitle}>
                  {slice.primary.titre_vert}
                </Text>
                <div className={style.row}>
                  <Text variant="span">{slice.primary.label_vert_1}</Text>
                  <Text variant="p">{slice.primary.text_vert_1}</Text>
                </div>
                <div className={cn(style.row, style.list)}>
                  <PrismicRichText field={slice.primary.liste_vert} />
                </div>
                <div className={cn(style.row, style.last)}>
                  <Text variant="span">{slice.primary.label_vert_2}</Text>
                  <Text variant="p">{slice.primary.text_vert_2}</Text>
                </div>
                <div className={style.indice}>
                  <Text variant="p" html={slice.primary.recap_vert[0].text} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default MangezMieux;
