export const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2.2,
        centerMode: false,
        dots: false,
        centerPadding: "50px 0px",
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.2,
        dots: false,
        centerPadding: "35px 0px",
      },
    },
  ],
};
