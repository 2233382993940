import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { useState } from "react";
import { ModalVideo } from "@components/commun/ModalVideo/ModalVideo";
import { scrollToSection } from "@middleware/helpers";
import { Text } from "@components/ui";
import style from "./InKitchend.module.scss";

const InKitchend = ({ slice }) => {
  const [show, setShow] = useState(false);

  return (
    <section id="inKitchen">
      <div className={style.kitchen}>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-blc-title-s1 center">
              <div className="splayer">
                <PrismicRichText field={slice.primary.title} />
              </div>
            </div>
          </div>
        </div>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className={style.mauro}>
              <div className="lk-flex lk-flex-mob-colomn">
                <div className={style.desc}>
                  <div className={style.text}>
                    <PrismicRichText field={slice.primary.description} />
                    <Text variant="p" className={style.name}>
                      {slice.primary.name}
                    </Text>
                  </div>
                </div>
                <div
                  role="presentation"
                  className={style.feature}
                  onClick={() => {
                    setShow(true);
                    scrollToSection("inKitchen");
                  }}
                >
                  <div>
                    <Image
                      src={slice.primary.image.url}
                      alt={slice.primary.image.alt}
                      title={slice.primary.image.alt}
                      width="377"
                      height="432"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.partners}>
          <div className="lk-flex large lk-wrap space-between">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div className={style.feat}>
                  <Image
                    src={item.logo.url}
                    alt={item.logo.alt}
                    title={item.logo.alt}
                    width="223"
                    height="50"
                  />
                </div>
                <Text variant="p">«&nbsp;{item.text}&nbsp;»</Text>
              </div>
            ))}
          </div>
        </div>
        {show && (
          <div className={style.iframe}>
            <ModalVideo
              url="https://www.youtube.com/embed/ysK1a82_uRA"
              close={() => setShow(false)}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default InKitchend;
