export const fields = [
  {
    type: "email",
    name: "email",
    class: "input-text",
    label: "pages.sharingEmail",
    placeholder: "form.emailSponsoring",
    required: true,
  },
];
