import { Link } from "@components/ui";
import { useWindow } from "@middleware/hooks";
import React from "react";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { useTranslation } from "next-i18next";
import { URL_PAGE_FORMULES_2 } from "@middleware/constants";
import style from "./CookingWithMauro.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.CookingWithMauroSlice} CookingWithMauroSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CookingWithMauroSlice>} CookingWithMauroProps
 * @param { CookingWithMauroProps }
 */
const CookingWithMauro = ({ slice }) => {
  const { t } = useTranslation();
  const { isMobile } = useWindow();

  return (
    <section className={style.wrapper}>
      <div className="lk-wrap">
        {isMobile && (
          <EntryTitle className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </EntryTitle>
        )}
        <div className={style.row}>
          <div className={style.column}>
            {!isMobile && (
              <EntryTitle className={style.title}>
                <PrismicRichText field={slice.primary.title} />
              </EntryTitle>
            )}
            <div className={style.description}>
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className={style.action}>
              <Link
                href={URL_PAGE_FORMULES_2}
                className="default rounded maxContent simple"
              >
                {t("buttons.chooseMyFormula")}
              </Link>
            </div>
          </div>
          <div className={style.column}>
            <div className={style.img}>
              <PrismicImage field={slice.primary.image} />
            </div>
            <div className={style.quote}>
              <PrismicRichText field={slice.primary.quote} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CookingWithMauro;
