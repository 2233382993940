/* eslint-disable */
import React, { ReactNode, useEffect } from "react";
import cn from "clsx";
import style from "./TrustPilot.module.scss";
import TrustPilotSlider from "./parts/TrustPilotSlider";
import TrustPilotMini from "./parts/TrustPilotMini";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import Image from "next/image";
import QualityIcon from "@static/images/icons/quality-icon.png";

interface Props {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
}
export default function TrustPilot({ title, subtitle }: Props) {
  useEffect(() => {
    // Adjust height based on screen size
    const adjustHeight = () => {
      const widget = document.getElementById("trustpilot-widget");
      if (widget) {
        if (window.innerWidth <= 768) {
          widget.setAttribute("data-style-height", "300px");
        } else {
          widget.setAttribute("data-style-height", "140px");
        }
      }
    };

    adjustHeight();

    const loadTrustpilotScript = () => {
      const script = document.createElement("script");
      script.id = "trustpilot-script";
      script.type = "text/javascript";
      script.src =
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.async = true;
      script.onload = () => {
        if (window.Trustpilot) {
          const elements = document.getElementsByClassName("trustpilot-widget");
          const trustpilotElements = Array.from(elements) as HTMLElement[];
          if (trustpilotElements.length > 0) {
            trustpilotElements.map((item) => {
              window.Trustpilot.loadFromElement(item, true);
            });
          }
        }
      };
      document.head.appendChild(script);
    };

    loadTrustpilotScript();

    return () => {
      const script = document.getElementById("trustpilot-script");
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <div className={cn(style.container, "lk-wrap large")}>
      <div>
        {title && <EntryTitle className={style.title}>{title}</EntryTitle>}
        {subtitle && (
          <EntryTitle variant="h3" className={style.subtitle}>
            <Image alt="" src={QualityIcon} /> {subtitle}
          </EntryTitle>
        )}
      </div>
      <div className={style.wrapper}>
        <div className={style.miniWidget}>
          <TrustPilotMini />
        </div>
        <div className={style.sliderWidget}>
          <TrustPilotSlider />
        </div>
      </div>
    </div>
  );
}
