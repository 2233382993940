import React from "react";
import { PrismicRichText } from "@prismicio/react";
import style from "./BlockMealsCms.module.scss";

const BlockMealsCms = ({ slice }) => (
  <section>
    <div className="lk-wrap">
      <div className="lk-wrap-content">
        <div className={style.root}>
          <div className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </div>
          <div className={style.text}>
            <PrismicRichText field={slice.primary.description} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BlockMealsCms;
