import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { SliderSlick } from "@components/commun";
import { Text } from "@components/ui";
import style from "./SliderRepas.module.scss";

const SliderRepas = ({ slice }) => {
  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 629,
        settings: {
          centerMode: true,
          centerPadding: "90px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: "50px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section>
      <div className={style.slider}>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-blc-title-s1 multiple center">
              <div className="splayer">
                <PrismicRichText field={slice.primary.title} />
              </div>
            </div>
            <div className="lk-blc-stitle">
              <PrismicRichText field={slice.primary.sousTitre} />
            </div>
          </div>
        </div>
        <div className="dl-recette-slider">
          <SliderSlick settings={settings}>
            {slice?.items?.map((item, i) => (
              <div key={i} className={style.slide}>
                <div>
                  <Text variant="p">{item.titre}</Text>
                  <div className={style.feat}>
                    <Image
                      src={item.feature.url}
                      alt={item.feature.alt}
                      title={item.feature.alt}
                      width="293"
                      height="293"
                    />
                  </div>
                </div>
              </div>
            ))}
          </SliderSlick>
        </div>
      </div>
    </section>
  );
};

export default SliderRepas;
