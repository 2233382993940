import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import cn from "clsx";
import { Link, Text } from "@components/ui";
import defaultFeature from "@static/images/bg/default-bg.png";
import fakeFeature from "@static/images/bg/fake-feature.jpg";
import {
  SMALL_TABLET_SIZE,
  TABLET_SIZE,
  TRUSTPILOT_SCORE,
} from "@middleware/constants";
import style from "./Head.module.scss";

const HomeHead = ({ slice }) => {
  const { t } = useTranslation();
  const defaultWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  const [width, setWidth] = useState(defaultWidth);
  const [urlFeature, setUrlFeature] = useState(defaultFeature);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("load", updateWidth);
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("load", updateWidth);
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    if (width < SMALL_TABLET_SIZE) {
      setUrlFeature(slice.primary.background_mob.url);
    } else if (width < TABLET_SIZE) {
      setUrlFeature(slice.primary.background_tab.url);
    } else {
      setUrlFeature(slice.primary.background_pc.url);
    }
  }, [width, slice]);

  return (
    <section>
      <div className={cn("head", style.head)}>
        <div className={cn("lk-wrap", style.wrap)}>
          <Text variant="heading" html={slice.primary.title[0].text} />
          <PrismicRichText field={slice.primary.description} />
          <div className={style.action}>
            <Link href={slice.primary.cta_url.url} className="primary">
              {slice.primary.cta_text}
            </Link>
          </div>
          <div className={style.trustpilot}>
            <Image
              src="/static/images/home/picto-header.png"
              alt=""
              width="26"
              height="30"
            />
            <Text variant="p">
              {t("home.numberOne")}
              <Text variant="strong">{TRUSTPILOT_SCORE}</Text>
            </Text>
            <Image
              src="/static/images/home/picto-header-stars.png"
              alt=""
              width="146"
              height="30"
            />
            <Image
              src="/static/images/home/picto-header-trustpilot.png"
              alt=""
              width="92"
              height="30"
            />
          </div>
        </div>
        <img
          alt="Fake Feature"
          width="99999"
          height="99999"
          className={style.fakeImage}
          src={fakeFeature.src}
          fetchpriority="high"
        />

        <Image
          className={style.image}
          alt="feature top"
          src={urlFeature}
          fill
          style={{
            objectFit: "cover",
          }}
          quality={100}
          priority
        />
      </div>
    </section>
  );
};

export default HomeHead;
