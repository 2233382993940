import React from "react";
import { SliderSlick } from "@components/commun";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { sliderSettings } from "./SliderSettings";
import style from "./EcoFriendlyPractices.module.scss";
/**
 * @typedef {import("@prismicio/client").Content.EcoFriendlyPracticesSlice} EcoFriendlyPracticesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<EcoFriendlyPracticesSlice>} EcoFriendlyPracticesProps
 * @param { EcoFriendlyPracticesProps }
 */
const EcoFriendlyPractices = ({ slice }) => (
  <section className={style.wrapper}>
    <div className="lk-wrap">
      <EntryTitle className={style.title}>
        <PrismicRichText field={slice.primary.title} />
      </EntryTitle>
      <div className={style.items}>
        <SliderSlick settings={sliderSettings}>
          {slice.items.map((item) => (
            <div className={style.item}>
              <div className={style.icon}>
                <PrismicImage field={item.icon} />
              </div>
              <div className={style.label}>{item.label}</div>
              <div className={style.caption}>
                <PrismicRichText field={item.caption} />
              </div>
            </div>
          ))}
        </SliderSlick>
      </div>
    </div>
  </section>
);

export default EcoFriendlyPractices;
