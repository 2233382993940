import { EMediaType, TMediaType } from "@middleware/types";
import style from "../HomeHeadWithVideo.module.scss";

interface Props {
  poster: string;
  image?: string;
  videoUrl: string;
  type: TMediaType;
}
export default function Background({ poster, videoUrl, image, type }: Props) {
  return (
    <div className={style.bg}>
      <div
        className={style.poster}
        style={{ backgroundImage: `url(${poster})` }}
      ></div>
      <div className={style.video}>
        {type === EMediaType.Image ? (
          <div
            className={style.image}
            style={{ backgroundImage: `url(${image ?? ""})` }}
          ></div>
        ) : (
          <video autoPlay muted loop playsInline aria-hidden="true">
            <source src={videoUrl + "#t=0.1"} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
}
