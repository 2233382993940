import { PrismicRichText } from "@prismicio/react";

const ContentSimple = ({ slice }) => (
  <section>
    <div className="lk-wrap">
      <div className="lk-wrap-content">
        <div className="page-contenu">
          {slice?.items?.map((item, i) => (
            <div className="bloc-contenu" key={i}>
              {item.titre && (
                <div className="lk-titre">
                  <PrismicRichText field={item.titre} />
                </div>
              )}
              {item.text && (
                <div className="lk-text">
                  <PrismicRichText field={item.text} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default ContentSimple;
