import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  getFromStorage,
  setToStorage,
} from "@middleware/helpers/global/sessions";
import { EGroupCustomer } from "@middleware/types";
import { useAuth } from "./useAuth";

export const useCustomerGroup = () => {
  const router = useRouter();
  const { user, isAuthenticated } = useAuth();
  const [customerGroup, setCustomerGroup] = useState<string>(
    EGroupCustomer.NONE
  );

  const getFromStorageGroupCode = () => {
    return getFromStorage("groupCode") as string;
  };

  useEffect(() => {
    if (isAuthenticated === true && user !== null && user.groupCode !== null) {
      setToStorage("groupCode", user.groupCode);
      setCustomerGroup(getFromStorageGroupCode());

      return;
    }

    const queryCode = router.query.groupCode;
    if (typeof queryCode === "string") {
      setToStorage("groupCode", queryCode);
    }

    setCustomerGroup(getFromStorageGroupCode());
  }, [isAuthenticated, user, router]);

  return {
    customerGroup,
  };
};
