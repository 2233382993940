import cn from "clsx";
import { Text } from "@components/ui";
import style from "@components/pages/checkout/Success/Success.module.scss";

const SuccessReferralsBox = (props) => (
  <div className={cn(style.col, props.className)}>
    <Text variant="span" className={style.price}>
      {props.amount}
    </Text>
    <Text variant="span" className={style.label}>
      {props.label}
    </Text>
    <Text variant="p" className={style.text} html={props.text} />
  </div>
);

export default SuccessReferralsBox;
