import Image from "next/image";
import { FC } from "react";
import trustPilotIcon from "@static/images/icons/trustpilot-icon.png";
import { TRUSTPILOT_NUMBER_OF_STARS } from "@middleware/constants";
import style from "./TrustPilotRating.module.scss";

interface TrustPilotRatingProps {
  value: number;
}

const TrustPilotRating: FC<TrustPilotRatingProps> = ({ value }) => {
  const width = `${(value / TRUSTPILOT_NUMBER_OF_STARS) * 100}%`;

  return (
    <div className={style.rating}>
      <span style={{ width }} className={style.progress}></span>
      {Array.from({ length: TRUSTPILOT_NUMBER_OF_STARS }, (_, index) => (
        <span key={index}>
          <Image src={trustPilotIcon} alt="" width={25} />
        </span>
      ))}
    </div>
  );
};

export default TrustPilotRating;
