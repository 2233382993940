import { useTranslation } from "next-i18next";
import cn from "clsx";
import { Link, Text } from "@components/ui";
import style from "@components/pages/checkout/Success/Success.module.scss";
import Image from "next/image";
import { URL_PAGE_CUSTOMER_ORDER } from "@middleware/constants";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";

const DiscoverCustomerAccount = ({ slice }) => {
  const { t } = useTranslation();

  return (
    <div className={cn(style.step, style.discoverCustomer)}>
      <div className={style.top}>
        <Text variant="span">{slice?.primary.titre}</Text>
      </div>
      <div className={style.content}>
        <div className={style.feature}>
          <EntryTitle variant="h3" className={style.h3Title}>
            {slice?.primary.sous_titre}
          </EntryTitle>
          <Image
            src={slice?.primary.image.url}
            alt={slice?.primary.image.alt}
            width="132"
            height="143"
          />
        </div>
        <div className={style.action}>
          <Link
            href={URL_PAGE_CUSTOMER_ORDER}
            className="default rounded maxContent simple"
          >
            {t("success.iscoverMyClientSpace")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DiscoverCustomerAccount;
