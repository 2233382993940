import { PrismicRichText } from "@prismicio/react";
import React from "react";

const TitrePage = ({ slice }) => (
  <section>
    <div className="lk-wrap">
      <div className="lk-wrap-content">
        <div className="lk-blc-title-s1 multiple center">
          <div className="splayer">
            <PrismicRichText field={slice.primary.title} />
          </div>
        </div>
        <div className="lk-blc-stitle">
          <PrismicRichText field={slice.primary.description} />
        </div>
      </div>
    </div>
  </section>
);

export default TitrePage;
