import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IMessage } from "@middleware/types";

export const Notification = ({
  message,
  clear,
}: {
  message: IMessage;
  clear: () => void;
}) => {
  useEffect(() => {
    switch (message.type) {
      case "ERROR":
        toast.error(message.text, {});
        break;
      case "SUCCESS":
        toast.success(message.text, {});
        break;
      case "WARNING":
        toast.warn(message.text, {});
        break;
      default:
        break;
    }
    toast.clearWaitingQueue();

    return () => {
      clear();
    };
  }, [message, clear]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        limit={1}
        closeOnClick
        pauseOnHover
      />
    </>
  );
};
