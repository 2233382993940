import React from "react";
import { PrismicImage, PrismicLink, PrismicRichText } from "@prismicio/react";
import { useTranslation } from "next-i18next";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { SliderSlick } from "@components/commun";
import { Text } from "@components/ui";
import style from "./FeaturedArticles.module.scss";
import { sliderSettings } from "./SliderSettings";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedArticlesSlice} FeaturedArticlesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedArticlesSlice>} FeaturedArticlesProps
 * @param { FeaturedArticlesProps }
 */
const FeaturedArticles = ({ slice }) => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={style.wrapper}>
        <div className="lk-wrap">
          <EntryTitle className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </EntryTitle>
          <div className={style.articles}>
            <SliderSlick settings={sliderSettings}>
              {slice.items.map((item, index) => (
                <div key={index} className={style.article}>
                  <div className={style.image}>
                    <PrismicImage field={item.articleImage} />
                  </div>
                  <Text variant="strong" className={style.title}>
                    <PrismicRichText field={item.articleTitle} />
                  </Text>
                  <Text className={style.excerpt}>
                    <PrismicRichText field={item.articleExcerpt} />
                  </Text>
                  <PrismicLink field={item.articleLink} className={style.link}>
                    {t("buttons.readArticle")}
                  </PrismicLink>
                </div>
              ))}
            </SliderSlick>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedArticles;
