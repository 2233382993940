import { useCustomer } from "@middleware/hooks";
import Image from "next/image";
import Picto from "@static/images/icons/envolope.png";
import { ShareSponsorshipLink } from "./ShareSponsorshipLink/ShareSponsorshipLink";
import { ShareSponsorshipEmail } from "./ShareSponsorshipEmail/ShareSponsorshipEmail";
import style from "./BoxSponsorship.module.scss";

export const BoxSponsorship = () => {
  const { customer } = useCustomer();

  return customer ? (
    <div className={style.sponsoring}>
      <div className={style.sponsorship}>
        <div className={style.whiteBox}>
          <div className={style.shareEmail}>
            <div>
              <Image src={Picto} alt="Dietbon" className={style.icon} />
            </div>
            <ShareSponsorshipEmail customer={customer} />
          </div>
        </div>
        <div className={style.whiteBox}>
          <ShareSponsorshipLink customer={customer} />
        </div>
      </div>
    </div>
  ) : null;
};
