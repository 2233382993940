import React from "react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { Link } from "@components/ui";
import { URL_PAGE_FORMULES_2 } from "@middleware/constants";
import { useTranslation } from "next-i18next";
import style from "./DailyHealthyEatingChoices.module.scss";
import ChoiceItem from "./ChoiceItem";

/**
 * @typedef {import("@prismicio/client").Content.DailyHealthyEatingChoicesSlice} DailyHealthyEatingChoicesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<DailyHealthyEatingChoicesSlice>} DailyHealthyEatingChoicesProps
 * @param { DailyHealthyEatingChoicesProps }
 */
const DailyHealthyEatingChoices = ({ slice }) => {
  const { t } = useTranslation();

  return (
    <section className={style.wrapper}>
      <div className="lk-wrap">
        <EntryTitle className={style.title}>
          <PrismicRichText field={slice.primary.title} />
        </EntryTitle>
        <div className={style.row}>
          <div className={style.column}>
            <div className={style.img}>
              <PrismicImage field={slice.primary.mainImage} />
            </div>
          </div>
          <div className={style.column}>
            <div className={style.items}>
              <div>
                <ChoiceItem
                  icon={slice.primary.itemIcon1}
                  label={slice.primary.itemLabel1}
                  caption={slice.primary.itemCaption1}
                />
                <ChoiceItem
                  icon={slice.primary.itemIcon2}
                  label={slice.primary.itemLabel2}
                  className={style.noCaption}
                />
              </div>
              <div>
                <ChoiceItem
                  icon={slice.primary.itemIcon3}
                  label={slice.primary.itemLabel3}
                  className={style.noCaption}
                />
                <ChoiceItem
                  icon={slice.primary.itemIcon4}
                  label={slice.primary.itemLabel4}
                  caption={slice.primary.itemCaption4}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.action}>
          <Link
            href={URL_PAGE_FORMULES_2}
            className="default rounded maxContent simple"
          >
            {t("buttons.chooseMyFormula")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default DailyHealthyEatingChoices;
