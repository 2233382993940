import Image from "next/image";
import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import { useState } from "react";
import { BIG_TABLET_SIZE, SMALL_TABLET_SIZE } from "@middleware/constants";
import { ModalVideo } from "@components/commun/ModalVideo/ModalVideo";
import style from "./videoMauro.module.scss";

const VideoMauro = ({ slice }) => {
  const [show, setShow] = useState(false);

  const getCorrectUrl = ({ width }) => {
    if (width < SMALL_TABLET_SIZE) {
      return slice.primary.background_mob.url;
    } else if (width < BIG_TABLET_SIZE) {
      return slice.primary.background_tab.url;
    } else {
      return slice.primary.background_pc.url;
    }
  };

  return (
    <section>
      <div className={style.video}>
        <div className={style.top}>
          <div className="lk-wrap">
            <div className="lk-wrap-content">
              <div className="lk-blc-title-s1 multiple center">
                <div className="splayer">
                  <PrismicRichText field={slice.primary.title} />
                </div>
              </div>
              <div className="lk-blc-stitle">
                <PrismicRichText field={slice.primary.sousTitre} />
              </div>
            </div>
          </div>
        </div>
        <div className={cn(style.content, "vcontent")}>
          <div className={style.feature}>
            <Image
              className={style.image}
              src={slice.primary.background_mob.url}
              loader={getCorrectUrl}
              fill
              style={{
                objectFit: "cover",
              }}
            />
          </div>
          <span role="presentation" onClick={() => setShow(true)}>
            Play
          </span>
          {show && (
            <div>
              <ModalVideo
                url="https://www.youtube.com/embed/ysK1a82_uRA"
                close={() => setShow(false)}
              />
            </div>
          )}
        </div>
        <div className={style.bottom}>
          <div className="lk-wrap">
            <div className="lk-wrap-content">
              <PrismicRichText field={slice.primary.description} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoMauro;
