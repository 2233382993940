import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import style from "./FaqHead.module.scss";

const FaqHead = ({ slice }) => (
  <section>
    <div className={style.head}>
      <Image
        className={style.image}
        src={slice.primary.image.url}
        fill
        style={{
          objectFit: "cover",
        }}
        priority
      />
      <div className="lk-wrap">
        <div className="lk-blc-title-s1 center">
          <div className="splayer">
            <PrismicRichText field={slice.primary.title} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FaqHead;
