import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import style from "./BlocParrainage.module.scss";

const BlocParrainage = ({ slice }) => (
  <section>
    <div className={style.parrainage}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-center lk-wrap">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div className={style.feature}>
                  <Image
                    src={item.feature.url}
                    width="353"
                    height="266"
                    alt={item.feature.alt}
                    title={item.feature.alt}
                  ></Image>
                </div>
                <div className={style.price}>
                  <span>{item.price}</span>
                </div>
                <div className={style.label}>
                  <span>{item.label}</span>
                </div>
                <div className={style.text}>
                  <PrismicRichText field={item.text} />
                </div>
              </div>
            ))}
          </div>
          <div className={style.bottom}>
            <PrismicRichText field={slice.primary.text_bas} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BlocParrainage;
