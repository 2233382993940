import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import Image from "next/image";
import { Text } from "@components/ui";
import style from "./BlocAssiette.module.scss";

const BlocAssiette = ({ slice }) => (
  <section>
    <div className={style.assiette}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-blc-title-s1 center">
            <div className="splayer">
              <PrismicRichText field={slice.primary.title} />
            </div>
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-between align-items lk-flex-smaltab-colomn">
            <div className={style.feature}>
              <Image
                src={slice.primary.feature.url}
                alt={slice.primary.feature.alt}
                title={slice.primary.feature.alt}
                width="608"
                height="608"
              />
            </div>
            <div className={style.content}>
              <div>
                <div className={style.values}>
                  {slice?.items?.map((item, i) => (
                    <div className={cn(style.item, style[`it${i}`])} key={i}>
                      <div className={style.top}>
                        <Image
                          src={item.icon.url}
                          alt={item.icon.alt}
                          title={item.icon.alt}
                          width={item.icon.dimensions.width}
                          height={item.icon.dimensions.height}
                        />
                        <Text variant="span">{item.name}</Text>
                      </div>
                      <div
                        className={cn(style.val, {
                          [style.reverse]: item.daily_first,
                        })}
                      >
                        <div className={style.industriel}>
                          <Text variant="span">{item.value_industriel}</Text>
                        </div>
                        <div className={style.daily}>
                          <Text variant="span">{item.value_ky}</Text>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={style.labels}>
                  <Text variant="span">Plat kitchendaily</Text>
                  <Text variant="span">Plat industriel</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BlocAssiette;
