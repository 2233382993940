import React from "react";
import { PrismicRichText } from "@prismicio/react";
import style from "./GroupBundleInfo.module.scss";

const GroupBundleInfo = ({ slice }) => (
  <section className={style.root}>
    {slice.primary.description ? (
      <PrismicRichText
        className={style.text}
        field={slice.primary.description}
      />
    ) : (
      <p>start by editing this slice from inside Slice Machine!</p>
    )}
  </section>
);

export default GroupBundleInfo;
