import { IShemaMessage } from "@middleware/types";
import { object, string } from "yup";

const getSchema = (messages: IShemaMessage) => {
  return object().shape({
    email: string()
      .email(messages.invalidEmail)
      .required(messages.requiredField),
  });
};

export default getSchema;
