import { PrismicRichText } from "@prismicio/react";
import cn from "clsx";
import { useState } from "react";
import { RichText, Text } from "@components/ui";
import style from "./Faq.module.scss";

const Faq = ({ slice }) => {
  const [active, setActive] = useState(false);

  return (
    <section>
      {slice?.items?.map((item, i) => (
        <div
          role="presentation"
          className={cn(style.item, { [style.active]: active === i })}
          key={i}
          onClick={() => setActive(i)}
        >
          <Text variant="span" className={style.label}>
            {item.question}
          </Text>
          <div className={style.text}>
            <RichText>
              <PrismicRichText field={item.reponse} />
            </RichText>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Faq;
