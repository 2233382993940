import { useTranslation } from "next-i18next";
import { Container } from "@components/ui";
import { BottomFooter } from "./parts/BottomFooter/BottomFooter";

export const Footer = () => {
  const { t } = useTranslation("footer");

  return (
    <footer id="lk-footer">
      <Container>
        <BottomFooter translation={t} />
      </Container>
    </footer>
  );
};
