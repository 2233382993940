import { Link } from "@components/ui";
import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import style from "./OffrePreferentielle.module.scss";

const OffresPreferentielles = ({ slice }) => (
  <section>
    <div className={style.offres}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-blc-title-s1 multiple center">
            <div className="splayer">
              <PrismicRichText field={slice.primary.title} />
            </div>
          </div>
          <div className="lk-blc-stitle">
            <PrismicRichText field={slice.primary.description} />
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className={style.action}>
            <Link href={slice.primary.cta_url.url} className="primary">
              {slice.primary.cta_text}
            </Link>
          </div>
        </div>
      </div>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-between lk-wrap">
            {slice?.items?.map((item, i) => (
              <div className={style.item} key={i}>
                <div className={style.feat}>
                  <Image
                    src={item.feature.url}
                    alt={item.feature.alt}
                    title={item.feature.alt}
                    width="59"
                    height="68"
                  />
                </div>
                <div className={style.label}>
                  <PrismicRichText field={item.text} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default OffresPreferentielles;
