import { useWindow } from "@middleware/hooks";
import Image from "next/image";
import React from "react";
import { Text } from "@components/ui";
import { TRUSTPILOT_NUMBER_OF_STARS } from "@middleware/constants";
import QualityIcon from "@static/images/icons/quality-icon.png";
import TrustPilotRating from "@components/commun/TrustPilotRating/TrustPilotRating";
import TrustPilotLogo from "@static/images/icons/trustpilot-logo.png";
import style from "../HomeHeadWithVideo.module.scss";

interface Props {
  labelMobile: string;
  labelDesktop: string;
  rating: number;
}
export default function TrustPilotHeader({
  labelMobile,
  labelDesktop,
  rating,
}: Props) {
  const { isMobile } = useWindow();

  const RatingLabel = () => {
    const label = isMobile ? labelMobile : labelDesktop;

    return <Text>{`${label} - ${rating}/${TRUSTPILOT_NUMBER_OF_STARS}`}</Text>;
  };

  return (
    <div className={style.tp}>
      <div>
        <Image src={QualityIcon} alt="" />
        <RatingLabel />
      </div>
      <div className={style.rating}>
        <TrustPilotRating value={rating} />
      </div>
      <div>
        <Image src={TrustPilotLogo} alt="" />
      </div>
    </div>
  );
}
