import cn from "clsx";
import { Button, Text } from "@components/ui";
import { getReferralUrl } from "@middleware/helpers";
import { ICustomer } from "@middleware/types";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import Picto from "@static/images/icons/link-circle.png";
import Image from "next/image";
import style from "./ShareSponsorshipLink.module.scss";

interface Props {
  customer: ICustomer;
}

export const ShareSponsorshipLink = ({ customer }: Props) => {
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = useState(false);

  const referralUrl = getReferralUrl(
    customer.user.sponsorshipCoupon?.code ?? ""
  );

  const copyLink = async () => {
    await navigator.clipboard.writeText(referralUrl);
    setShowMessage(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showMessage) {
      timer = setTimeout(function () {
        setShowMessage(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showMessage]);

  return (
    <>
      <div className={style.wrapper}>
        <Image
          src={Picto}
          alt="Kitchendaily"
          className={style.icon}
          width={42}
        />
        <div className={style.content}>
          <Text variant="p" className={style.copyWith}>
            {t("pages.copyWith")}
          </Text>
          <Text variant="p">{t("pages.shareInSocialMedia")}</Text>
        </div>
        <div className={style.action}>
          <Button
            onClick={copyLink}
            className={cn(style.btn, "rounded maxContent")}
            variant="default"
            data-testid="link-button"
            puce={false}
          >
            {t("pages.shareMyLink")}
          </Button>
        </div>
      </div>
      {showMessage && (
        <Text variant="p" className={style.message}>
          {t("pages.linkCopied")}
        </Text>
      )}
    </>
  );
};
