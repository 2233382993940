import React from "react";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { PrismicRichText } from "@prismicio/react";
import { URL_PAGE_FORMULES_2 } from "@middleware/constants";
import { Link } from "@components/ui";
import { useTranslation } from "next-i18next";
import EightyDishesSlider from "./EightyDishesSlider";
import style from "./EightyDishes.module.scss";

/**
 * @typedef {import("@prismicio/client").Content.PreparedDishesCarouselSlice} PreparedDishesCarouselSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<PreparedDishesCarouselSlice>} PreparedDishesCarouselProps
 * @param { PreparedDishesCarouselProps }
 */
const PreparedDishesCarousel = ({ slice }) => {
  const { t } = useTranslation();

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className={style.wrapper}>
        <div>
          <EntryTitle isPinned={true} variant="h2" className={style.title}>
            <PrismicRichText field={slice.primary.title} />
          </EntryTitle>
        </div>
        <div>
          <EntryTitle isPinned={true} variant="h3" className={style.subtitle}>
            <PrismicRichText field={slice.primary.subtitle} />
          </EntryTitle>
        </div>
        <div className={style.items}>
          <EightyDishesSlider slice={slice} />
        </div>
      </div>
      <div className={style.descouverButton}>
        <Link
          href={URL_PAGE_FORMULES_2}
          className="default simple rounded maxContent"
        >
          {t("buttons.chooseMyFormula")}
        </Link>
      </div>
    </section>
  );
};

export default PreparedDishesCarousel;
