import { Text } from "@components/ui";
import Image from "next/image";
import { Loyalty } from "./Loyalty";
import style from "./BlockParrainage.module.scss";

const BlockParrainage = ({ slice }) => (
  <section className={style.root}>
    <div className={style.feature}>
      <Image
        className={style.image}
        src="/static/images/checkout/Illustration-sponsor.svg"
        width={275}
        height={183}
        alt=""
      />
    </div>
    <div className={style.parrainage}>
      <Loyalty
        imageUrl={slice.primary.image_parrain.url}
        imageAlt={slice.primary.image_parrain.alt}
        amount={slice.primary.montant_parrain}
        label={slice.primary.label_parrain}
        description={slice.primary.text_parrain}
      />
      <Loyalty
        imageUrl={slice.primary.image_filleul.url}
        imageAlt={slice.primary.image_filleul.alt}
        amount={slice.primary.montant_filleul}
        label={slice.primary.label_filleul}
        description={slice.primary.text_filleul}
      />
    </div>
    <div className={style.info}>
      <Text variant="p" html={slice.primary.text_informatif} />
    </div>
  </section>
);

export default BlockParrainage;
