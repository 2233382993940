import cn from "clsx";
import { Text } from "@components/ui";
import style from "@components/pages/checkout/Success/Success.module.scss";
import Image from "next/image";
import EntryTitle from "@components/commun/EntryTitle/EntryTitle";
import { BoxSponsorship } from "@components/sponsorship/BoxSponsorship";
import SuccessReferralsBox from "./SuccessReferralsBox";

const SuccessReferrals = ({ slice }) => (
  <div className={cn(style.step, style.stepSponsorship)}>
    <div className={style.top}>
      <Text variant="span" html={slice?.primary.titre} />
    </div>
    <div className={style.boxParrainage}>
      <EntryTitle variant="h3" className={style.h3Title}>
        {slice?.primary.sous_titre}
      </EntryTitle>
      <Image
        className={style.image}
        src="/static/images/checkout/Illustration-sponsor.svg"
        width={215}
        height={140}
        alt=""
      />
      <div className="lk-flex">
        <SuccessReferralsBox
          className={style.first}
          amount={slice?.primary.amount_parrain}
          label={slice?.primary.label_parrain}
          text={slice?.primary.text_parrain}
        />
        <SuccessReferralsBox
          className={style.last}
          amount={slice?.primary.amount_filleul}
          label={slice?.primary.label_filleul}
          text={slice?.primary.text_filleul}
        />
      </div>

      <div className={style.sponsorship}>
        <BoxSponsorship />
      </div>
    </div>
  </div>
);

export default SuccessReferrals;
