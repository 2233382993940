import { FC } from "react";
import { SliderSlick } from "@components/commun";
import { Text } from "@components/ui";
import { Content } from "@prismicio/client";
import style from "./EightyDishes.module.scss";
import { sliderSettings } from "./SliderSettings";

interface EightyDishesSliderProps {
  slice: Content.PreparedDishesCarouselSlice;
}

const EightyDishesSlider: FC<EightyDishesSliderProps> = ({ slice }) => {
  return (
    <SliderSlick settings={sliderSettings}>
      {slice.items.map((item, index) => (
        <div className={style.item} key={index}>
          <div
            style={{
              backgroundImage: `url(${item.dishImage.url ?? ""})`,
            }}
          >
            <div className={style.bottom}>
              <div>
                <Text className={style.productName}>{item.dishName}</Text>
              </div>
            </div>
          </div>
        </div>
      ))}
    </SliderSlick>
  );
};

export default EightyDishesSlider;
