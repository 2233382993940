import React from "react";
import cn from "clsx";
import style from "./EntryTitle.module.scss";

interface Props {
  children: React.ReactNode;
  variant?: "h1" | "h2" | "h3" | "h4";
  className?: string;
}
function EntryTitle({ children, variant = "h2", className }: Props) {
  return <div className={cn(className, style[variant])}>{children}</div>;
}

export default EntryTitle;
