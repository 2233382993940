import PrismicDom from "prismic-dom";
import cn from "clsx";
import { useEffect, useState } from "react";
import { Link, Text } from "@components/ui";
import { getFormattedDiscountAmount } from "@middleware/helpers";
import { usePromotionDetails } from "@middleware/hooks";
import style from "./BarPromotion.module.scss";

const BarPromotion = ({ slice }) => {
  const [isReady, setIsReady] = useState(false);
  const initPromotionText = PrismicDom.RichText.asHtml(slice.primary.text);
  const { promotion } = usePromotionDetails();
  const [promotionText, setPromotionText] = useState(initPromotionText);

  useEffect(() => {
    if (promotion) {
      const updatedText = initPromotionText
        .replace(
          /<em>[\s\S]*?<\/em>/,
          "<em>-" +
            getFormattedDiscountAmount(
              promotion.discountAmount,
              promotion.discountType
            ) +
            "</em>"
        )
        .replace(
          /<strong>[\s\S]*?<\/strong>/,
          "<strong>" + promotion.promotionCoupon + "</strong>"
        );
      setPromotionText(updatedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotion]);

  const handleScroll = () => {
    setIsReady(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 1800);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={cn(style.bar, { [style.isScrolled]: isReady })}>
      <div className="lk-wrap">
        <div className="lk-wrap-content">
          <div className="lk-flex space-center">
            <div className={style.boxReduction}>
              <Text variant="body" html={promotionText} />
            </div>
            <div className={style.boxAction}>
              <Link
                href={slice.primary.cta_url.url}
                className="simple default rounded maxContent"
              >
                {slice.primary.cta_text}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BarPromotion;
