import Image from "next/image";
import { Text } from "@components/ui";
import style from "./Partners.module.scss";

const Partners = ({ slice }) => (
  <section>
    <div className={style.partners}>
      <div className="lk-flex lk-wrap space-between">
        {slice?.items?.map((item, i) => (
          <div className={style.item} key={i}>
            <div className={style.feat}>
              <Image
                src={item.feature.url}
                alt={item.feature.alt}
                title={item.feature.alt}
                width="223"
                height="50"
              />
            </div>
            <Text variant="p">«&nbsp;{item.text}&nbsp;»</Text>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Partners;
