import { PrismicRichText } from "@prismicio/react";
import Image from "next/image";
import { useEffect, useState } from "react";
import { SliderSlick } from "@components/commun";
import { Text } from "@components/ui";
import style from "./AvisGoute.module.scss";

const AvisClients = ({ slice }) => {
  const [width, setWidth] = useState(1024);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const items = slice?.items?.map((item, i) => (
    <div className={style.item} key={i}>
      <div className={style.feat}>
        <Image
          src={item.score_image.url}
          alt={item.score_image.alt}
          title={item.score_image.alt}
          width="376"
          height="66"
        />
      </div>
      <Text variant="p">{item.text}</Text>
      <br />
      <Text variant="span">
        {item.name}, <br />
        {item.ville}
      </Text>
    </div>
  ));

  return (
    <section>
      <div className={style.goute}>
        <div className={style.feature}>
          <Image
            className={style.image}
            src={slice.primary.background_left.url}
            width={310}
            height={285}
          />
          <Image
            className={style.image}
            src={slice.primary.background_right.url}
            width={303}
            height={444}
          />
        </div>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className="lk-blc-title-s1 center">
              <div className="splayer">
                <PrismicRichText field={slice.primary.title} />
              </div>
            </div>
          </div>
        </div>
        <div className="slide-avis lk-wrap">
          <div className="lk-wrap-content">
            {width > 629 && (
              <div className="lk-flex space-between">{items}</div>
            )}
            {width < 630 && (
              <div className={style.slides}>
                <SliderSlick settings={settings}>{items}</SliderSlick>
              </div>
            )}
          </div>
        </div>
        <div className="only_mobile">
          <div className={style.featMobile}>
            <Image
              src={slice.primary.background_left.url}
              alt={slice.primary.background_left.alt}
              title={slice.primary.background_left.alt}
              width="597"
              height="563"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AvisClients;
