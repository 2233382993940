import React from "react";

export default function TrustPilotSlider() {
  return (
    <div
      className="trustpilot-widget"
      data-locale="fr-FR"
      data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id="5fb289cd9a4f790001adf989"
      data-style-height="240px"
      data-style-width="100%"
      data-theme="light"
      data-stars="4,5"
      data-review-languages="fr"
    >
      <a
        href="https://fr.trustpilot.com/review/kitchen-daily.fr"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
}
