import React from "react";
import AccordionComponent from "@components/commun/Accordion/Accordion";
import { PrismicRichText } from "@prismicio/react";
import style from "./Accordion.module.scss";
/**
 * @typedef {import("@prismicio/client").Content.AccordionSlice} AccordionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<AccordionSlice>} AccordionProps
 * @param { AccordionProps }
 */
const Accordion = ({ slice }) => {
  const data = slice.items.map((item) => ({
    headerTitle: <PrismicRichText field={item.headTitle} />,
    bodyContent: <PrismicRichText field={item.bodyContent} />,
  }));

  return (
    <section className={style.wrapper}>
      <div>
        <AccordionComponent items={data} />
      </div>
    </section>
  );
};

export default Accordion;
